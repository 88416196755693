<template>
    <v-container>

        <user-form class="my-5 mx-auto" :loading="loading" @send="changePassword"/>

        <v-footer padless fixed color="primary" dark app>
            <v-col class="text-center" cols="12">
                Desde el año 2001 apoyando a las medianas empresas chilenas y extranjeras haciendo negocios en nuestro pais.
            </v-col>
        </v-footer>

    </v-container>
</template>

<script>
import UserForm from "@/components/entrance/UserForm";
import Axios from "@/utils/Axios";
import {mapMutations} from "vuex";

export default {
    name: "ChangePassword",
    components: {UserForm},
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        ...mapMutations(['setAccount']),
        async changePassword(payload) {
            const vm = this
            vm.loading = true

            // console.log('changePassword', payload)

            await Axios.post('auth/change-password', payload).then(res => {
                // console.log('res changePassword', res)
                if(res.data.user) {
                    vm.setAccount(res.data.user)
                    vm.$router.push('home')
                } else {
                    vm.toast(res.data.message, 'error')
                }
            }).catch(error => {
                console.log('error changePassword', error)
            }).finally(() => {
                vm.loading = false
            })
        }
    }
}
</script>